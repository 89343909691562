<template>
    <card-list-item @click="$emit('click')" :class="{'active': active}" @details="toggleDetails">
        <template v-slot:icon><i class="fa-fw fa-plane-departure" :class="{'fas': active, 'far': !active}"></i></template>
        <template v-slot:content>
            <div class="flight-title">{{flight.depart_airport.name}} ({{flight.depart_airport.iata}})</div>
            <div class="meta mb-2" v-if="!details && showDates">{{departLocal}}</div>
            <div class="meta" v-if="!details && itinerary"><i class="far fa-clock fa-fw mr-2"></i>{{departLocalTime}} - {{arriveLocalTime}}</div>

            <div class="meta" v-if="!details"><i class="far fa-fw fa-plane mr-2"></i>{{flight.arrive_airport.name}} ({{flight.arrive_airport.iata}})</div>
            <div class="meta" v-if="!details && showDates">{{arriveLocal}}</div>

            <div class="meta" v-if="details">{{departLocal}} - {{flight.depart_airport.city}}, {{flight.depart_airport.country}}</div>

            <div class="mt-1 mb-1 is-size-7" v-if="details"><i class="far fa-ellipsis-h"></i><i class="fas fa-plane ml-2 mr-2"></i><b-tag>{{duration}}</b-tag></div>
            <div class="flight-title" v-if="details">{{flight.arrive_airport.name}} ({{flight.arrive_airport.iata}})</div>
            <div class="meta" v-if="details">{{arriveLocal}} - {{flight.arrive_airport.city}}, {{flight.arrive_airport.country}}</div>
            <div class="meta mt-1" v-if="showName"><i class="far fa-fw fa-user mr-2"></i>{{flight.last_name}}, {{flight.first_name}}</div>
        </template>
        <template v-slot:status>
            <div class="stats">
                <span class="stat" v-if="flight.primary_flight"><b-tooltip position="is-left" label="Primary Flight"><i class="far fa-flag"></i></b-tooltip></span>
                <span class="stat" v-if="flight.has_arrived"><b-tooltip position="is-left" label="Arrived/Accounted For"><i class="far fa-user-check"></i></b-tooltip></span>
                <b-tag type="is-warning" class="stat" v-if="!flight.task_complete"><i class="fas fa-tasks"></i></b-tag>
            </div>
        </template>
        <template v-slot:details>
            <div class="user-details">
                <toolbar class="toolbar mt-2">
                    <b-tooltip label="Registration">
                        <button class="icon-only" @click.stop="$openCard('registration', {registrationId: flight.registration_id}, card)"><i class="far fa-suitcase-rolling"></i></button>
                    </b-tooltip>
                    <b-tooltip label="Related Flights">
                        <button class="icon-only" @click.stop="$openCard('flights', {registrationId: flight.registration_id}, card)"><i class="far fa-plane"></i></button>
                    </b-tooltip>
                </toolbar>

                <div class="data mt-1">
                    <data-row mini title="Participant" v-if="flight.registration && flight.registration.user && flight.applies_to === 'primary'">{{flight.registration.user.last_name}}, {{flight.registration.user.first_name}} (Primary)</data-row>
                    <data-row mini title="Participant" v-else-if="flight.registration && flight.registration.second_user && flight.applies_to === 'secondary'">{{flight.registration.second_user.last_name}}, {{flight.registration.second_user.first_name}} (Secondary)</data-row>
                    <data-row mini title="Participant" v-else-if="flight.applies_to === 'both'">Both Participants</data-row>
                    <data-row mini title="Arranged By" v-if="flight.arranged_by === 'tv'">Tennis Ventures</data-row>
                    <data-row mini title="Arranged By" v-if="flight.arranged_by === 'self'">Participant</data-row>
                    <data-row mini title="Operator">{{flight.operator}}</data-row>
                    <data-row mini title="Flight #">{{flight.flight_number}}</data-row>
                    <data-row mini title="Class">{{flight.travel_class | capitalize}}</data-row>
                    <data-row mini title="ID">{{flight.id}}</data-row>
                </div>
            </div>
        </template>
    </card-list-item>
</template>

<script>
    import Toolbar from "../TIER/components/Toolbar";
    import DataRow from "../TIER/components/DataRow";
    import CardListItem from "../TIER/components/CardListItem";
    import moment from "moment-timezone";

    export default {
        name: 'FlightListItem',
        components: {CardListItem, DataRow, Toolbar},
        props: {
            card: {
                required: true
            },
            flight: {
                type: Object,
                required: true
            },
            active: {
                type: Boolean,
                default: false
            },
            showName: {
                type: Boolean,
                default: false
            },
            showDates: {
                type: Boolean,
                default: false
            },
            showDetails: {
                type: Boolean,
                default: false
            },
            itinerary: {
                type: Boolean,
                default: false
            }
        },
        data: function () {
            return {
                details: false
            };
        },
        methods: {
            toggleDetails(d) {
                this.details = d;
            }
        },
        computed: {
            duration: function () {
                if (this.flight.arrive_at && this.flight.depart_at) {
                    let min = moment.duration(moment(this.flight.arrive_at).diff(this.flight.depart_at)).asMinutes();
                    let hours = Math.floor(min / 60);
                    let minutes = min % 60;
                    if (hours > 0) {
                        return hours + ' hour' + (hours !== 1 ? 's' : '') + ', ' + minutes + ' minute' + (minutes !== 1 ? 's' : '');
                    } else {
                        return minutes + ' minutes';
                    }
                }
            },
            departLocal: function () {
                if (this.flight.depart_at)
                    return moment.utc(this.flight.depart_at).tz(this.flight.depart_airport.tz_database).format('MMM Do, YYYY, h:mm A');
            },
            arriveLocal: function () {
                if (this.flight.arrive_at)
                    return moment.utc(this.flight.arrive_at).tz(this.flight.arrive_airport.tz_database).format('MMM Do, YYYY, h:mm A');
            },
            departLocalTime: function () {
                if (this.flight.depart_at)
                    return moment.utc(this.flight.depart_at).tz(this.flight.depart_airport.tz_database).format('h:mm A');
            },
            arriveLocalTime: function () {
                if (this.flight.arrive_at)
                    return moment.utc(this.flight.arrive_at).tz(this.flight.arrive_airport.tz_database).format('h:mm A');
            }
        },
        mounted() {
            this.details = this.showDetails;
        }
    };
</script>

<style lang="scss" scoped>

    li {
        color: darken($t-primary, 10);

        .flight-title {
            font-size: .9rem;
            font-weight: 700;
        }

        padding: 0.5rem 0;

        .user-details {
            margin-top: 0.5rem;
        }
    }
</style>
